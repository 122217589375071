@import url("https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap");
@font-face {
  font-family: "Match";
  src: url("/public/Match-Regular.otf");
  font-weight: 400;
}
@font-face {
  font-family: "Match";
  src: url("/public/Match-Regular.otf");
  font-weight: 500;
}
@font-face {
  font-family: "Match";
  src: url("/public/Match-Bold.otf");
  font-weight: 700;
}
@font-face {
  font-family: "SF Pro Display";
  src: url("/public/SF-Pro-Display-Regular.otf");
  font-weight: 400;
}
@font-face {
  font-family: "SF Pro Display";
  src: url("/public/SF-Pro-Display-Bold.otf");
  font-weight: 700;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  @apply leading-[normal] m-0;
}
*,
::before,
::after {
  border-width: 0;
}
